import * as React from "react";

import Marquee from "react-fast-marquee";

import deltaLogo from "../images/delta-logo.png";
import DeltaOutline from "../images/delta-outline-bg.svg";

import Eye from "../images/eye.png";
import Cube from "../images/cube.png";
import Dollar from "../images/dollar.png";
import Objects from "../images/objects.png";
import Padlock from "../images/padlock.png";
import User from "../images/user.png";

import "../styles/index.scss";
import CollapseCard from "../components/collapse.card";
import CryptoCard from "../components/crypto.card";

// markup
const IndexPage = () => {
	return (
		<>
			<main>
				<DeltaOutline className="delta-bg" />
				<img src={deltaLogo} className="delta-logo" />
				<h1 style={{ marginTop: 0 }} className="pre-wrap">
					{"Buy or Sell Crypto.\nEasy. Fast. Discrete."}
				</h1>
				<div style={{ display: "flex", alignItems: "center" }}>
					<a href="https://app.deltaotc.com">
						<button style={{ marginRight: 12 }} className="primary-button">
							Enter The App
						</button>
					</a>
					<a href="#why-delta">
						<button className="outline-button">Why Delta?</button>
					</a>
				</div>
				<div style={{ marginTop: 200 }} className="section" id="why-delta">
					<h2 className="section-title">Why Delta OTC?</h2>
					<hr />
					<div className="why-delta-container">
						<div className="card">
							<img src={Dollar} alt="dollar-icon" className="card-icon" />
							<h3>Best Guaranteed Price</h3>
							<p className="text-gray">
								We have a team of experienced and skilled traders, and cooperate with the world's biggest crypto
								liquidity providers, in order to provide you the best possible prices for trading. We guarantee
								execution of your trade at the price you accept on our platform.
							</p>
							<a href="https://app.deltaotc.com">
								<button className="secondary-button">Ask For a Price</button>
							</a>
						</div>
						<div className="card">
							<img src={Eye} alt="eye-icon" className="card-icon" />
							<h3>Discrete</h3>
							<p className="text-gray">
								We value your privacy and work in complete confidentiality with our clients and their data. Your trades,
								entry prices or any of your actions will remain completely private.
							</p>
						</div>
						<div className="card">
							<img src={User} alt="user-icon" className="card-icon" />
							<h3>Friendly User Experience</h3>
							<p className="text-gray">
								Our #1 priority is for clients to be happy with our service, so we simplified and automated the whole
								process, as we know that these things can be very overwhelming, especially for the newcomers. Working
								with us, you will save time, energy, and avoid common errors.
							</p>
							<a href="https://app.deltaotc.com">
								<button className="secondary-button">Try Our App</button>
							</a>
						</div>
						<div className="card">
							<img src={Padlock} alt="padlock-icon" className="card-icon" />
							<h3>Secure</h3>
							<p className="text-gray">
								We work with one of the world's most secure custodians in order to keep your funds safe. In order to add
								an extra security layer, all withdrawals are executed manually. You can deposit or request withdrawal of
								your funds at any time, and we will process it as soon as possible during our working hours.
							</p>
							<a href="https://app.deltaotc.com">
								<button className="secondary-button">Try Our Wallet</button>
							</a>
						</div>
						<div className="card">
							<img src={Cube} alt="cube-icon" className="card-icon" />
							<h3>Crypto Market Expertise</h3>
							<p className="text-gray">
								We have gathered a team of highly skilled individuals who have spent years trading on the emerging
								crypto market. As our client you will have full access to connect and talk with us about market analysis
								and recap every day, and receive white-glove support.
							</p>
						</div>
						<div className="card">
							<img src={Objects} alt="objects-icon" className="card-icon" />
							<h3>Most Trading Pairs Available</h3>
							<p className="text-gray">
								Our clients benefit from a wide range of trading pairs. In addition to the traditional crosses, we offer
								a wide range of altcoin trading and storage services. Our trading infrastructure allows clients to trade
								with flexibility, from small automated order execution to multi-million OTC blocks.
							</p>
						</div>
					</div>
				</div>

				<div className="section" id="#how-it-works">
					<h2 className="section-title">Supported Assets</h2>
					<hr />
					<Marquee pauseOnHover={true} style={{ filter: "blur(0px)" }} speed={40} gradient={false}>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/btc@2x.png`).default}
							currencyName="Bitcoin"
							color="#F6931A"
							currencyTicker="btc"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/eth@2x.png`).default}
							currencyName="Ethereum"
							color="#617DE6"
							currencyTicker="eth"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/dot@2x.png`).default}
							currencyName="Polkadot"
							color="#E5287A"
							currencyTicker="dot"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/bnb@2x.png`).default}
							currencyName="Binance Coin"
							color="#F8DC97"
							currencyTicker="bnb"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/xtz@2x.png`).default}
							currencyName="Tezos"
							color="#A5DF01"
							currencyTicker="xtz"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/xrp@2x.png`).default}
							currencyName="Ripple"
							color="#AAAAAA"
							currencyTicker="xrp"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/usdt@2x.png`).default}
							currencyName="Tether"
							color="#26A07B"
							currencyTicker="usdt"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/ada@2x.png`).default}
							currencyName="Cardano"
							color="#584cb6"
							currencyTicker="ada"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/doge@2x.png`).default}
							currencyName="Dogecoin"
							color="#C2A534"
							currencyTicker="doge"
						/>
						<CryptoCard
							icon={require("../../node_modules/crypto-icons-plus-64/src/fantom.png").default}
							currencyName="Fantom"
							color="#26B5E9"
							currencyTicker="ftm"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/sol@2x.png`).default}
							currencyName="Solana"
							color="#66F8A0"
							currencyTicker="sol"
						/>
						<CryptoCard
							icon={require("../../node_modules/crypto-icons-plus-64/src/thorchain.png").default}
							currencyName="Thorchain"
							color="#12CDCC"
							currencyTicker="rune"
						/>
					</Marquee>
					<Marquee
						direction="right"
						pauseOnHover={true}
						style={{ filter: "blur(0px)", marginTop: -30 }}
						speed={40}
						gradient={false}
					>
						<CryptoCard
							icon={require("../../node_modules/crypto-icons-plus-64/src/avalanche.png").default}
							currencyName="Avalanche"
							color="#E74142"
							currencyTicker="avax"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/uni@2x.png`).default}
							currencyName="Uniswap"
							color="#FE2C7A"
							currencyTicker="uni"
						/>
						<CryptoCard
							icon={require("../../node_modules/crypto-icons-plus-64/src/terra-luna.png").default}
							currencyName="Terra"
							color="#FED73D"
							currencyTicker="luna"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/snx@2x.png`).default}
							currencyName="Synthetix"
							color="#5FCCF8"
							currencyTicker="snx"
						/>
						<CryptoCard
							icon={require("../../node_modules/crypto-icons-plus-64/src/1inch.png").default}
							currencyName="1INCH"
							color="#D2DBEA"
							currencyTicker="1INCH"
						/>
						<CryptoCard
							icon={require("../../node_modules/crypto-icons-plus-64/src/curve-dao-token.png").default}
							currencyName="Curve"
							color="#F9C903"
							currencyTicker="crv"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/matic@2x.png`).default}
							currencyName="Polygon"
							color="#6F41D8"
							currencyTicker="matic"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/dai@2x.png`).default}
							currencyName="Dai"
							color="#F9C903"
							currencyTicker="dai"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/usdc@2x.png`).default}
							currencyName="USD Coin"
							color="#3E73C4"
							currencyTicker="usdc"
						/>
						<CryptoCard
							icon={require("../../node_modules/crypto-icons-plus-64/src/ftx-token.png").default}
							currencyName="FTX Token"
							color="#94DFED"
							currencyTicker="ftt"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/link@2x.png`).default}
							currencyName="Chainlink"
							color="#295ADA"
							currencyTicker="link"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/atom@2x.png`).default}
							currencyName="Cosmos"
							color="#2E3148"
							currencyTicker="atom"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/etc@2x.png`).default}
							currencyName="Ethereum Classic"
							color="#328232"
							currencyTicker="etc"
						/>
						<CryptoCard
							icon={require(`../../node_modules/cryptocurrency-icons/32@2x/color/zec@2x.png`).default}
							currencyName="ZCash"
							color="#ECB144"
							currencyTicker="zec"
						/>
					</Marquee>
					<h4 className="text-gray">All supported assets are paired with USD, EUR and CHF</h4>
				</div>
				<div className="section" id="#how-it-works">
					<h2 className="section-title">How It Works</h2>
					<hr />
					<div className="how-it-works-container">
						<div className="how-card">
							<h2>1</h2>
							<h3>Registration</h3>
							<p>
								First, you need to go to our app to sign up, and declare yourself as a retail or a corporate client.
								Depending on the nature of your account declaration, you will get different information fields which are
								required to be filled in order to proceed. When you've verified your email, you can continue with your
								registration.
							</p>
						</div>
						<div className="how-card">
							<h2>2</h2>
							<h3>Submission of documentation and Identification </h3>
							<p>
								As a crypto service provider, we need to take you through KYC (Know-Your-Customer) and AML
								(Anti-Money-Laundering) procedures and verify your identity. Once you've finished the sign-up and
								verified your email, under the Profile tab you will find all the necessary information you will need to
								submit for us to successfully verify your identity.
							</p>
						</div>
						<div style={{ display: "flex", flexDirection: "column", flex: 3 }}>
							<div className="how-card">
								<h2>3</h2>
								<h3>Accepting Terms and Conditions</h3>
							</div>
							<div className="how-card">
								<h2>4</h2>
								<h3>Account opening</h3>
								<p>After completing the previous 3 steps, your account is open and fully functional.</p>
							</div>
						</div>
						<div className="how-card">
							<h2>5</h2>
							<h3>Trade</h3>
							<p>
								In our app, we have sophisticated software offering you the best possible prices for buying or selling
								your crypto, which is available under the Home tab. There is a very short, limited time period where you
								can accept/reject the offered price. You choose whether or not you wish to buy at the price offered. If
								you agree, the trade is confirmed and your wallet balance will be changed according to your order.
							</p>
						</div>
					</div>
				</div>
				<div className="section" id="#how-it-works">
					<h2 className="section-title">Frequently Asked Questions</h2>
					<hr />
					<div style={{ display: "flex", flexDirection: "column", paddingTop: 20 }}>
						<CollapseCard
							title="When will my account be opened?"
							text="We will open your Delta OTC account as soon as your account opening request is reviewed and approved. Usually it doesn't take more than 2-3 days."
						/>
						<CollapseCard
							title="When can I start using Delta OTC services?"
							text="You can start using our services as soon as your account is approved, and you have deposited fiat or cryptocurrencies to your account. A minimum initial deposit requirement is 2500 EUR (or the equivalent amount of supported asset) for private and corporate clients."
						/>
						<CollapseCard
							title="What coins can I transact in?"
							text={`Our supported assets are:

- Bitcoin (BTC)
- Ethereum (ETH)
- Ethereum Classic (ETC)
- Tezos (XTZ)
- Dai (DAI)
- Polygon (MATIC)
- Avalanche (AVAX)
- USD Coin (USDC)
- Terra (LUNA)
- Polkadot (DOT)
- Cardano (ADA)
- Binance Coin (BNB)
- Thorchain (RUNE)
- FTX Token (FTT)
- Fantom (FTM)
- Dogecoin (DOGE)
- Solana (SOL)
- Synthetix (SNX)
- Sia Coin (SC)
- 1INCH Token (1INCH)
- Curve Finance (CRV)
- Uniswap Token (Uni)
- Chainlink (LINK)
- Cosmos (ATOM)
- Ripple (XRP)
- ZCash (ZEC)
- Tether (USDT)
\n\nAll assets are paired with USD, EUR, CHF and USDT.`}
						/>
						<CollapseCard
							title="Does asset deposit involve any additional fees?"
							text="Crypto and fiat deposits are completely free. However, please note that your bank might charge you a fee for the transfer."
						/>
						<CollapseCard
							title="How do I deposit fiat assets on Delta OTC?"
							text="Once you are onboarded and KYC verified, using the web apllication, go to the selected fiat currency in your wallet, click DEPOSIT and follow the wire instructions."
						/>
						<CollapseCard
							title="Do I need to pay tax while using your services?"
							text=" We do not charge tax for using our services, but you have to calculate your taxes based on your jurisdiction and profile. Cryptocurrencies in certain jurisdictions are subject to taxes, however, we do not charge VAT."
						/>
						<h3 style={{ marginTop: 20, marginBottom: 10 }}>OTC Desk</h3>
						<CollapseCard
							title="Does Delta OTC match buyers and sellers for OTC services?"
							text="All trades we execute are with our own capital and we do not look to match up buyers and sellers."
						/>
						<CollapseCard
							title="Can transaction settlements be sent to a third party account after OTC trades?"
							text="Our KYC / AML policies dictate that we must always face the entity that was onboarded for
              any trades that we excute and that the bank account beneficiaries are also named identically to the name used in the trading agreement signed by the counterparty."
						/>
						<CollapseCard
							title="What are your fees for OTC trading?"
							text="We do not charge any outright fees. The spread is calculated by including many factors like market volatility, liquidity of the underlying asset being traded and the size of the trade."
						/>
						<CollapseCard
							title="What is the minimum order amount for buy/sell?"
							text="The minimum trading amount for using our OTC Desk is 2500 EUR (or the equivalent amount of supported asset) for private and corporate clients. You can buy and sell only above the equivalent of this amount. We maintain a minimum trading amount to ensure the high quality of our trading activities and client support in line with the current financial markets environment."
						/>
						<CollapseCard
							title="How does Delta OTC execute trades?"
							text="Delta OTC trades everyday over the most popular exchanges globally to find the best offer for our clients. This means, that we will select one or more liquidity providers that provide you with a combination of the best possible market price and speed of execution with minimal counterparty risk."
						/>
					</div>
				</div>
			</main>
			<footer>
				<img src={deltaLogo} className="delta-logo" />
				<p className="text-gray">Copyright Delta OTC {new Date().getFullYear()}</p>
			</footer>
		</>
	);
};

export default IndexPage;

