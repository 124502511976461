import axios from "axios";

export let coinList = Array<any>();
axios.get("https://api.coingecko.com/api/v3/coins/list?include_platform=false").then((res) => {
	coinList = res.data;
});

export const getCoin = (ticker: string, name: string) => {
	let coin = coinList.find(
		(item) =>
			item.symbol?.toLowerCase() === ticker.toLowerCase() &&
			((item.id ? item.id.toLowerCase().includes(name.split(" ")[0].toLowerCase().substring(0, 4)) : true) ||
				(item.name ? item.name.toLowerCase().includes(name.toLowerCase().substring(0, 4)) : true)) &&
			!item.id?.includes("wormhole") &&
			!item.id?.includes("long") &&
			!item.id?.includes("short") &&
			!item.id?.includes("baby") &&
			!item.id?.includes("-peg-")
	);
	return coin;
};

export const getChartForCoin = async (ticker: string, name: string) => {
	let coin = getCoin(ticker, name);
	if (coin?.id) {
		let resp = await axios.get(
			`https://api.coingecko.com/api/v3/coins/${coin?.id}/market_chart?vs_currency=usd&days=1`
		);
		return resp.data.prices;
	}
};

