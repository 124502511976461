import React, { useState } from "react";

import { Collapse } from "react-collapse";

type CollapseCardProps = {
	title: string;
	text: string;
};

export default function CollapseCard(props: CollapseCardProps) {
	const [open, setOpen] = useState(false);

	return (
		<div onClick={() => setOpen(!open)} className="card collapse" style={{ cursor: "pointer" }}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<h3 style={{ marginRight: 30 }}>{open ? "-" : "+"}</h3>
				<h3>{props.title}</h3>
			</div>
			<Collapse isOpened={open}>
				<p className="pre-wrap">{props.text}</p>
			</Collapse>
		</div>
	);
}

