import React, { useEffect, useState } from "react";

import { Line, LineChart, ResponsiveContainer, YAxis } from "recharts";

import { getChartForCoin } from "./coingecko.api";

type WalletCardProps = {
	currencyTicker: string;
	currencyName: string;
	color?: string;
	icon: any;
};

export default function CryptoCard(props: WalletCardProps) {
	const [graphData, setGraphData] = useState([] as { price: number; name: string }[]);
	const [currentPrice, setCurrentPrice] = useState(0);
	const [percentChange, setPercentChange] = useState(0);

	const getData = async () => {
		let coinData = await getChartForCoin(props.currencyTicker, props.currencyName);
		if (coinData) {
			setCurrentPrice(coinData[coinData.length - 1][1]);
			setPercentChange(((coinData[coinData.length - 1][1] - coinData[0][1]) / coinData[0][1]) * 100);
			let gData = [];
			let interval = Math.floor(coinData.length / 10);
			for (let i = 0; i < coinData.length - 1; i += interval) {
				gData.push({
					name: new Date(coinData[i][0]).toLocaleDateString("sl-SI"),
					price: coinData[i][1],
				});
			}
			setGraphData(gData);
		} else {
			setTimeout(() => {
				getData();
			}, 500);
		}
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ paddingTop: 20, paddingBottom: 20 }}>
			<div
				className="crypto-card"
				style={{ backgroundColor: props.color + "44", border: `1px solid ${props.color + "77"}` }}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<img
						alt={`${props.currencyTicker}-icon`}
						src={props.icon}
						style={{
							height: 100,
							marginRight: 20,
							marginTop: 0,
							marginBottom: 14,
							borderRadius: "50%",
							overflow: "hidden",
						}}
					/>
					<div>
						<h3 style={{ marginRight: 10, marginTop: 0 }}>{props.currencyName}</h3>
						<h4 style={{ marginTop: 0, marginBottom: 0 }} className="text-gray">
							{props.currencyTicker.toUpperCase()}
						</h4>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20 }}>
								<p style={{ marginBottom: 0 }} className="text-gray">
									Price
								</p>
								<h4 style={{ marginTop: 0 }}>{currentPrice.toFixed(2)} USD</h4>
							</div>
							<div>
								<p style={{ marginBottom: 0 }} className="text-gray">
									24h Change
								</p>
								<h4 style={{ marginTop: 0, color: percentChange > 0 ? "#0fda90" : "red" }}>
									{percentChange.toFixed(2)}%
								</h4>
							</div>
						</div>
					</div>
				</div>

				<ResponsiveContainer width="100%" height={45}>
					<LineChart data={graphData}>
						<Line
							isAnimationActive={false}
							type="monotone"
							dataKey="price"
							stroke={props.color ? props.color : "#18B388"}
							strokeWidth={1}
						/>
						<YAxis type="number" domain={["dataMin", "dataMax"]} hide={true} />
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
}



